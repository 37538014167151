/* Import Fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

/* Global Page Settings */
body {
    font-family: "Inter", Arial;
    background-color: var(--neutral-900);
    color: var(--neutral-0);
    line-height: 1.6;
}

/* Headings */
h1 {
    font-family: 'Playfair Display', serif;
    font-size: 3.75rem;
    letter-spacing: 0.05em;
    line-height: 120%;
}

/* Adjust font size for small screens (phones) */
@media (max-width: 768px) {
    h1 {
        font-size: 2.75rem;
    }
}

h2 {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    letter-spacing: 0.05em;
    line-height: 122%;
}

h3 {
    font-family: 'Playfair Display', serif;
    font-size: 2.25rem;
    letter-spacing: 0.05em;
    line-height: 122%;
}

/* Adjust font size for small screens (phones) */
@media (max-width: 768px) {
    h3 {
        font-size: 1.5rem;
    }
}

h4 {
    font-family: 'Playfair Display', serif;
    font-size: 1.875rem;
    letter-spacing: 0.05em;
    line-height: 120%;
    color: var(--neutral-0);
    margin-bottom: 0.5rem;
}

h5 {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    line-height: 125%;
}

/* Paragraphs */
.p1 {
    font-family: 'Inter', Arial;
    font-size: 1.25rem;
    line-height: 150%;
}

/* Adjust font size for small screens (phones) */
@media (max-width: 768px) {
    .p1 {
        font-size: 1rem;
    }
}
